<template>
	<div class="content nav" :class="scrollFixed === true ? 'isFixed' : ''">

		<div class="bottom1 flexs">
			<div class="leftp">
				<router-link to="/"><img src="../assets/img/logo.png" alt=""></router-link>
			</div>
			<div class="leftphone">
				<router-link to="/"><img src="../assets/img/phone/logo-m.png" alt=""></router-link>
			</div>
			<div class="right1 flexc">
				<el-menu router :default-active="activeIndex" @select="handleSelect" class="el-menu-demo"
					mode="horizontal" text-color="#000" active-text-color="#3e84f6">
					<el-menu-item index="/">首页</el-menu-item>
					<el-submenu index="1">

						<template slot="title">产品及服务</template>
						<el-submenu index="1-4">
							<template slot="title">安全稳定的硬件能力
							</template>
							<el-menu-item index="/bigDipper">北斗融合终端</el-menu-item>
							<el-menu-item index="/broken">智能断路器</el-menu-item>
							<el-menu-item index="/fcfz">FCSZ电能综合治理终端</el-menu-item>
						</el-submenu>
						<el-submenu index="2-4">
							<template slot="title">全链路应用服务
							</template>
							<!-- <el-menu-item><a href="https://www.yellowrivercloudcable.cn/"
									target="_blank">电缆商城</a></el-menu-item> -->
							<el-menu-item><a href="https://www.yellowrivercloudcable.com/"
									target="_blank">黄河云缆</a></el-menu-item>
							<el-menu-item><a href="https://bj.yrccable.com/#/" target="_blank">自动识别报价</a></el-menu-item>
							<el-menu-item><a href="http://218.29.109.70:11784/#/order"
									target="_blank">产品溯源系统</a></el-menu-item>
							<el-menu-item><a href="https://wzf.hnymqq.com/" target="_blank">外综服</a></el-menu-item>
							<!-- <el-menu-item index="/cloudcable">云缆汇</el-menu-item> -->
							<el-menu-item><a href="http://gyl.yrccable.com/" target="_blank">供应链管理平台</a></el-menu-item>
							<el-menu-item><a href="http://218.29.109.70:11786" target="_blank">MES系统</a></el-menu-item>
							<el-menu-item><a href="http://dcp.huatailianhe.com" target="_blank">数采平台</a></el-menu-item>
							<el-menu-item><a href="http://bj.huatailianhe.com/"
									target="_blank">电缆询价系统</a></el-menu-item>
							<el-menu-item><a href="https://baas.yrccable.com/index"
									target="_blank">区块链系统</a></el-menu-item>
							<el-menu-item><a href="https://xs.yrccable.com/#/" target="_blank">项目报备</a></el-menu-item>
						</el-submenu>

					</el-submenu>
					<el-submenu index="6">
						<template slot="title">解决方案</template>
						<el-menu-item index="/secure">
							安全用电解决方案
						</el-menu-item>
						<el-menu-item index="/electricity">
							电能综合治理解决方案
						</el-menu-item>
						<!-- <el-menu-item index="6-3">
              数字化工厂解决方案
            </el-menu-item>
            <el-menu-item index="6-4">
              产品溯源方案
            </el-menu-item>
            <el-menu-item index="6-5">
              智慧外贸解决方案
            </el-menu-item> -->
					</el-submenu>
					<!-- <el-menu-item index="3">云市场</el-menu-item> -->
					<el-menu-item> <a href="https://www.yellowrivercloudcable.cn/"
							target="_blank">云缆商城</a></el-menu-item>
					<el-menu-item index="/cloudcable">云缆汇</el-menu-item>
					<el-menu-item index="/identification">云缆标识</el-menu-item>
					<el-menu-item index="/developer">开发者中心</el-menu-item>
					<el-menu-item index="/about">关于华泰</el-menu-item>
				</el-menu>
			</div>
			<div class="login flexs" v-if="!phone">
				<p @click="login()">登录</p>
				<div class="logon">
					<el-link href="http://cas.huatailianhe.com/#/passport/register">
						<div style="color: #fff; ">免费注册</div>
					</el-link>
				</div>
			</div>
			<div class="user flexs login" v-else>
				<a href="http://cas.huatailianhe.com/#/dashboard/home">
					<p style="color: #006CFF;">{{phone}}</p>
				</a>
				<div style="color: #242323; margin-right: 30px;cursor: pointer;" @click="remove()">注销</div>
			</div>
			<div class="pright">
				<div class="p-menu">
					<i class="el-icon-s-unfold" :class="{'picons': isok}" @click="change"></i>
					<div class="menu" v-if="isok">
						<el-menu class="el-menu-demo" mode="vertical" text-color="#333333" active-text-color="#3e84f6"
							router :default-active="activeIndex" style="width: 200px;" @select="handleSelect">
							<el-menu-item index="/">首页</el-menu-item>
							<el-submenu index="1">
								<template slot="title">产品及服务</template>

								<el-submenu index="2-4">
									<template slot="title">全链路应用服务
									</template>
									<el-menu-item>电缆商城<a href="https://www.yellowrivercloudcable.cn/"
											target="_blank"></a></el-menu-item>
									<el-menu-item><a href="https://www.yellowrivercloudcable.com/"
											target="_blank">黄河云缆</a></el-menu-item>
									<el-menu-item><a href="https://bj.yrccable.com/#/"
											target="_blank">自动识别报价</a></el-menu-item>
									<el-menu-item><a href="http://218.29.109.70:11784/#/order"
											target="_blank">产品溯源系统</a></el-menu-item>
									<el-menu-item><a href="https://wzf.hnymqq.com/"
											target="_blank">外综服</a></el-menu-item>
									<!-- <el-menu-item index="/cloudcable">云缆汇</el-menu-item> -->
									<el-menu-item><a href="http://gyl.yrccable.com/"
											target="_blank">供应链管理平台</a></el-menu-item>
									<el-menu-item><a href="http://218.29.109.70:11786"
											target="_blank">MES系统</a></el-menu-item>
									<el-menu-item><a href="http://dcp.huatailianhe.com"
											target="_blank">数采平台</a></el-menu-item>
									<el-menu-item><a href="http://bj.huatailianhe.com/"
											target="_blank">电缆询价系统</a></el-menu-item>
									<el-menu-item><a href="https://baas.yrccable.com/index"
											target="_blank">区块链系统</a></el-menu-item>
									<el-menu-item><a href="https://xs.yrccable.com/#/"
											target="_blank">项目报备</a></el-menu-item>
								</el-submenu>
								<el-submenu index="1-4">
									<template slot="title">安全稳定的硬件能力
									</template>
									<el-menu-item index="/bigDipper">北斗融合终端</el-menu-item>
									<el-menu-item index="/broken">智能断路器</el-menu-item>
									<el-menu-item index="/fcfz">FCSZ电能综合治理终端</el-menu-item>
								</el-submenu>
							</el-submenu>
							<el-submenu index="6">
								<template slot="title">解决方案</template>
								<el-menu-item index="/secure">
									安全用电解决方案
								</el-menu-item>
								<el-menu-item index="/electricity">
									电能综合治理解决方案
								</el-menu-item>
								<!-- <el-menu-item index="6-3">
                  数字化工厂解决方案
                </el-menu-item>
                <el-menu-item index="6-4">
                  产品溯源方案
                </el-menu-item>
                <el-menu-item index="6-5">
                  智慧外贸解决方案
                </el-menu-item> -->
							</el-submenu>
							<!-- <el-menu-item index="3">云市场</el-menu-item> -->
							<el-menu-item> <a href="https://www.yellowrivercloudcable.cn/"
									target="_blank">云缆商城</a></el-menu-item>
							<el-menu-item index="/cloudcable">云缆汇</el-menu-item>
							<el-menu-item index="/identification">云缆标识</el-menu-item>
							<el-menu-item index="/developer">开发者中心</el-menu-item>
							<el-menu-item index="/about">关于华泰</el-menu-item>
							<el-menu-item index="/login">登录</el-menu-item>
						</el-menu>
					</div>
				</div>

			</div>
		</div>
	</div>
</template>
<script>
	import Cookies from "js-cookie";
	import {
		queryAuthorizationUrl,
		queryLogoutUrl
	} from "@/utils/api";
	export default {
		data() {
			return {
				input4: '',
				scrollFixed: false,
				offsetTop: 0,
				activeIndex: '/',
				isok: false,
				isshow: false,
				phone: ''
			}
		},
		methods: {
			handleSelect(key) {
				if (key != null) {
					this.activeIndex = key
					// 获取导航选中,存储vx防止跳转丢失
					this.$store.commit("change", key)
					sessionStorage.setItem('activeIndex', key)
					// 存kookies 有效期7天
					// Cookies.set('activeIndex', key, {
					// 	expires: 7
					// })
				}
			},
			remove() {
				this.phone = ''
				Cookies.remove('phone')
				queryLogoutUrl().then(response => {
					window.location.href = response.data.data
				})
				// this.$message({
				//   showClose: true,
				//   message: '已注销',
				//   offset: '100',
				//   type: 'success'
				// })
			},
			windowScroll() {
				// 滚动条顶部 距 滚动原点的高度
				let scrollTop =
					window.pageYOffset ||
					document.documentElement.scrollTop ||
					document.body.scrollTop;
				/**
				 * 三目运算
				 * 两个高度比较
				 * 如果滑动距离 > 吸顶元素到页面顶端距离  动态添加
				 */
				scrollTop > this.offsetTop ?
					(this.scrollFixed = true) :
					(this.scrollFixed = false);
			},
			change() {
				this.isok = !this.isok
			},
			login() {
        window.location.href = 'http://218.28.2.170:8055/ht/control/#/application/index';
				// queryAuthorizationUrl().then(response => {
				// 	window.location.href = response.data.data
				// })
			}
		},
		mounted() {
			// 需吸顶元素 距 离浏览器顶端的高度
			this.offsetTop = document.querySelector(".nav").offsetTop;
			// 滚动监听
			window.addEventListener("scroll", this.windowScroll);
		},
		created() {
			this.phone = Cookies.get('phone');
			if (this.$store.state.activeIndex == undefined || null) {
				this.activeIndex = '/'
			} else {
				this.activeIndex = this.$store.state.activeIndex
			}

		},
		destroyed() {
			// 关闭 销毁监听
			window.removeEventListener("scroll", this.windowScroll);
		},
	}
</script>
<style lang="scss" scoped>
	.isFixed {
		width: 100%;
		position: fixed;
		top: 0;
		left: 0;
		z-index: 999;
		background: rgba(255, 255, 255, 0.6);
		opacity: 1;
		color: #000;
	}

	a {
		text-decoration: none;
	}

	::v-deep .el-icon-arrow-down {
		color: #fff !important;
	}

	::v-deep .el-menu-demo {
		display: flex;

	}



	.serve {
		color: #fff;
		cursor: pointer;
		margin: 0 50px 0 80px;
		display: flex;
		align-items: center;

		.el-input__icon {
			font-size: 25px;
			margin-right: 5px;
		}
	}

	.leftphone {
		display: none;
	}

	.content {
		width: 100%;
		height: 64px;

		z-index: 99999;

		.top1 {
			height: 36px;
			justify-content: space-between;
			padding: 0 200px;
			background-color: #fff;


			p {
				font-size: 14px;
				font-family: PingFang SC;
				font-weight: 500;
				color: #006CFF;
			}
		}

		.bottom1 {
			width: 100%;
			height: 64px;
			background-color: #f6f6f6;
			justify-content: space-between;
			padding: 0px;

			.leftp {
				color: #fff;
				padding-left: 30px;
			}



			.right1 {
				.el-menu-demo {
					border: none;
					background-color: rgba(0, 0, 0, 0);
				}



				.el-input {
					margin-left: 30px;
					width: 150px;
					border-radius: 50px;
				}

				.user {
					display: flex;
					align-items: center;
					color: #fff;
					margin-left: 20px;

					.el-icon-user-solid {
						font-size: 25px;
					}

					span {
						display: inline-block;
						margin-left: 5px;
						cursor: pointer;
					}

					span:hover {
						color: #000000;
					}
				}
			}

			.pright {
				display: none;

				i {
					transition: all 1s;
				}

			}

		}

		.login {
			p {
				margin-right: 30px;
			}

			.logon {
				padding: 0 20px;
				height: 64px;
				line-height: 64px;
				background-color: #2065FC;
				color: #fff;
			}
		}
	}

	@media only screen and (max-width: 1680px) {
		.content {
			.top1 {
				padding: 0 15px;
			}

			.bottom1 {
				padding: 0 15px;
			}
		}

	}

	@media only screen and (max-width: 1440px) {
		.content {
			.top1 {
				padding: 0 10px;
			}

			.bottom1 {
				padding: 0 10px;

				.leftp {
					display: none;
				}

				.leftphone {
					display: block;
				}
			}
		}

	}

	@media only screen and (max-width: 1280px) {
		.content {
			.top1 {
				padding: 0 10px;
			}

			.bottom1 {
				padding: 0 10px;
			}

			.el-input {
				display: none;
			}

			.logon {
				display: none;

			}

			.login {
				p {
					margin-right: 0;
				}
			}
		}

	}


	@media only screen and (max-width: 1030px) {

		::v-deep .el-menu-demo {
			display: flex;
			flex-direction: column;
		}

		.content {
			height: 64px !important;

			.picons {
				transform: rotate(90deg);
			}

			.login {
				display: none;
			}


			.top1 {
				display: none;
			}

			.bottom1 {
				padding: 0 30px;

				.right1 {
					display: none;
				}

				.pright {
					display: block;


					.p-menu {
						position: relative;

						.el-icon-s-unfold {
							font-size: 33px;
							color: #000;
						}

						.menu {
							position: absolute;
							top: 48px;
							left: -136px;
							z-index: 999999999999999;
						}
					}
				}
			}
		}
	}

	@media only screen and (max-width: 768px) {

		::v-deep .el-menu-demo {
			display: flex;
			flex-direction: column;
		}

		.content {
			height: 64px !important;

			.picons {
				transform: rotate(90deg);
			}



			.top1 {
				display: none;
			}

			.bottom1 {
				padding: 0 10px;



				.right1 {
					display: none;
				}

				.login {
					display: none;
				}

				.pright {
					display: block;


					.p-menu {
						position: relative;

						.el-icon-s-unfold {
							font-size: 35px;
							color: #000;
						}

						.menu {
							position: absolute;
							top: 48px;
							left: -136px;
							z-index: 999999999999999;
						}

						::v-deep .menu {
							height: 100vh;
							overflow-y: scroll;
						}
					}
				}
			}
		}
	}
</style>