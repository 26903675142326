import request from "./request.js"
// 获取首页数据
export function getInfo(data) {
	return request({
		url: '/api/index',
		method: 'get',
		data
	})
}
// 云缆汇 云缆标识 提交表单
export function saveFinancial(data) {
	return request({
		url: '/api/financial/saveFinancial',
		method: 'post',
		data
	})
}
// 获取验证码
export function sendSms(data) {
	return request({
		url: `/api/sms/sendSms?phone=${data}`,
		method: 'get',
	})
}
// 登录
export function login(data) {
	return request({
		url: '/api/login',
		method: 'post',
		data
	})
}
// 查询授权地址
export function queryAuthorizationUrl() {
	return request({
		url: '/api/oauth2/queryAuthorizationUrl',
		method: 'get'
	})
}
// 注册
export function register(data) {
	return request({
		url: '/api/register',
		method: 'post',
		data
	})
}
// 查询新闻列表
export function listPage(data, num) {
	return request({
		url: '/api/section/listPage',
		method: 'get',
		params: {
			current: data,
			size: num
		}
	})
}
// 查询新闻详情
export function querySectioninfo(data) {
	return request({
		url: `/api/section/querySectioninfo/${data}`,
		method: 'get',

	})
}
// 注销
export function queryLogoutUrl() {
	return request({
		url: '/api/oauth2/queryLogoutUrl',
		method: 'get'
	})
}
// 保存融资需求
export function saveFinancingDemand(data) {
	return request({
		url: '/api/financial/saveFinancingDemand',
		method: 'post',
		data
	})
}