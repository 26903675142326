import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'element-ui/lib/theme-chalk/index.css';
import {
	Message
} from 'element-ui';
import {
	Button,
	Select,
	Menu,
	MenuItem,
	Submenu,
	Input,
	Carousel,
	CarouselItem,
	Row,
	Col,
	Pagination,
	Form,
	FormItem,
	Backtop,
	Tabs,
	TabPane,
	Avatar,
	Table,
	TableColumn,
	Divider,
	Dialog,
	Link,
	Steps,
	Step,
	Radio,
	RadioGroup,
	DatePicker,
	Option,
	TimePicker
} from 'element-ui';
Vue.config.productionTip = false
// import axios from 'axios'
// Vue.prototype.$http = axios
Vue.prototype.$message = Message
// Vue.config.productionTip = false
Vue.use(Button)
Vue.use(Select)
Vue.use(Menu)
Vue.use(MenuItem)
Vue.use(Submenu)
Vue.use(Input)
Vue.use(Carousel)
Vue.use(CarouselItem)
Vue.use(Row)
Vue.use(Col)
Vue.use(Pagination)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Backtop)
Vue.use(Tabs)
Vue.use(TabPane)
Vue.use(Avatar)
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Divider)
Vue.use(Dialog)
Vue.use(Link)
Vue.use(Steps)
Vue.use(Step)
Vue.use(Radio)
Vue.use(RadioGroup)
Vue.use(DatePicker)
Vue.use(Option)
Vue.use(TimePicker)
new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app')