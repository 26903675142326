<template>
	<div>
		<div class="banner">
			<el-carousel :interval="5000" arrow="always" :height="carouselHeight + 'px'">
				<el-carousel-item v-for="(item, index) in pics" :key="index">
					<img :src="item.img" ref="carouselImage" alt="" @load="calculateCarouselHeight"
						style="object-fit: contain;" width="100%" />
				</el-carousel-item>
			</el-carousel>
		</div>
		<div class="bannerpc">
			<el-carousel :interval="5000" arrow="always" height="600px">
				<el-carousel-item v-for="(item, index) in pic" :key="index">
					<img :src="item.img" alt="" style="object-fit: cover;" width="100%" />
				</el-carousel-item>
			</el-carousel>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				pics: [{
						img: require("../assets/img/banner1.webp")
					},
					{
						img: require("../assets/img/banner2.webp")
					},
					{
						img: require("../assets/img/banner3.webp")
					},
					{
						img: require("../assets/img/banner4.webp")
					},
					{
						img: require("../assets/img/banner5.webp")
					},
				],
				pic: [{
						img: require("../assets/img/phone/banner11.webp")
					},
					{
						img: require("../assets/img/phone/banner22.webp")
					},
					{
						img: require("../assets/img/phone/banner33.webp")
					},
					{
						img: require("../assets/img/phone/banner44.webp")
					},
					{
						img: require("../assets/img/phone/banner55.webp")
					},
				],
				carouselHeight: 0, // 用于保存轮播高度的变量
			};
		},
		mounted() {
			// 监听窗口大小变化，以便在窗口大小变化时重新计算轮播高度
			window.addEventListener('resize', this.calculateCarouselHeight);
			// 利用$nextTick确保图片已加载
			this.$nextTick(() => {
				this.calculateCarouselHeight();
			});
		},
		updated() {
			// 利用$nextTick确保图片已加载，并在组件更新后重新计算高度
			this.$nextTick(() => {
				this.calculateCarouselHeight();
			});
		},
		methods: {
			calculateCarouselHeight() {
				// 获取第一个图片元素的高度
				const imageElement = this.$refs.carouselImage[0];
				if (imageElement) {
					if (imageElement.offsetHeight == 0) {
						this.carouselHeight = 600
					} else {
						this.carouselHeight = imageElement.offsetHeight;
					}
				}
			},
		},
		beforeDestroy() {
			// 移除窗口大小变化事件监听器，以防止内存泄漏
			window.removeEventListener('resize', this.calculateCarouselHeight);
		},
	};
</script>


<style lang="scss" scoped>
	.bannerpc {
		display: none;
	}

	@media only screen and (max-width: 500px) {
		.banner {
			display: none;
		}

		.bannerpc {
			display: block;
		}
	}
</style>