import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import store from '../store'
Vue.use(VueRouter)

const routes = [{
		path: '/',
		name: 'home',
		component: HomeView
	},
	{
		path: '/about',
		name: 'about',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import( /* webpackChunkName: "about" */ '../views/AboutView.vue')
	},
	{
		path: '/new',
		name: 'new',
		component: () => import('../views/NewView.vue')
	},

	{
		path: '/newdetails',
		name: 'newdetails',
		component: () => import('../views/Newdetails.vue')
	},
	{
		path: '/aptitude',
		name: 'aptitude',
		component: () => import('../views/AptitudeView.vue')
	},
	// {
	// 	path: '/cloudcable',
	// 	name: 'cloudcable',
	// 	component: () => import('../views/Cloudcable.vue')
	// },
	{
		path: '/identification',
		name: 'identification',
		component: () => import('../views/Identification.vue')
	},
	{
		path: '/developer',
		name: 'developer',
		component: () => import('../views/Developer.vue')
	},
	{
		path: '/login',
		name: 'login',
		component: () => import('../views/Login.vue')
	},
	{
		path: '/electricity',
		name: 'electricity',
		component: () => import('../views/Electricity.vue')
	},
	{
		path: '/secure',
		name: 'secure',
		component: () => import('../views/Secure.vue')
	},
	{
		path: '/bigDipper',
		name: 'bigDipper',
		component: () => import('../views/BigDipper.vue')
	},
	{
		path: '/fcfz',
		name: 'fcfz',
		component: () => import('../views/Fcfz.vue')
	},
	{
		path: '/broken',
		name: 'broken',
		component: () => import('../views/Broken.vue')
	},
	{
		path: "/Share/towechat",
		name: "towechat",
		component: () => import("../views/towechat.vue"),
	},
	{
		path: "/cloudLogin",
		name: "cloudLogin",
		component: () => import("../views/Cloud/Login.vue"),
	},
	{
		path: "/cloudcable",
		name: "cloudcable",
		component: () => import("../views/Cloud/Cloudcable.vue"),
	},
	{
		path: "/consult",
		name: "consult",
		component: () => import("../views/Cloud/Consult.vue"),
	},
	{
		path: "/ylxcons",
		name: "ylxcons",
		component: () => import("../views/Cloud/Ylxcons.vue"),
	},
	{
		path: "/elLogin",
		name: "elLogin",
		component: () => import("../views/Cloud/ElLogin.vue"),
	},
	{
		path: "/egLogin",
		name: "egLogin",
		component: () => import("../views/Cloud/EgLogin.vue"),
	},
	{
		path: "/egcons",
		name: "egcons",
		component: () => import("../views/Cloud/Egcons.vue"),
	},
	{
		path: "/elcons",
		name: "elcons",
		component: () => import("../views/Cloud/Elcons.vue"),
	}, {
		path: "/clause",
		name: "clause",
		component: () => import("../views/text/clause.vue"),
	}, {
		path: "/agreement",
		name: "agreement",
		component: () => import("../views/text/agreement.vue"),
	}
]

const router = new VueRouter({
	mode: 'history',
	routes
})
router.beforeEach((to, from, next) => {
	// 在这里可以获取到当前的路径地址
	const currentPath = to.path;
	// 可以在这里进行一些操作，例如记录日志，检查用户权限等
	store.commit("change", currentPath)
	sessionStorage.setItem('activeIndex', currentPath)
	// 继续路由导航
	next();
});
export default router