import axios from 'axios'
// import store from '@/store'

var request = axios.create({
  baseURL: "/",
  timeout: 5000,
  // headers: {
  //   'TOKEN': localStorage.getItem("istoken")

  // }
})
request.interceptors.request.use(
  function (res) {
    // res.headers.TOKEN = localStorage.getItem("istoken")

    return res
  },
  function (err) {
    return err
  }
)
request.interceptors.response.use(
  function (res) {
    return res
  },
  function (err) {
    return err
  }
)
export default request