<template>
	<div class="container">
		<Nav></Nav>
		<Swiper></Swiper>
		<div class="one">
			<el-row :gutter="20" type="flex" justify="center">
				<el-col :span="4" :xs="12" class="one-center">
					<div class="one-item flexc">
						<img src="@/assets/img/huiyuanshu.png" alt="">
						<div class="one-text">
							<p><span>{{ member }}</span>家</p>
							<p class="p1">会员数</p>
						</div>
					</div>
				</el-col>
				<el-col :span="4" :xs="12" class="one-center">
					<div class="one-item flexc">
						<img src="@/assets/img/jiaoyie.png" alt="">
						<div class="one-text">
							<p><span>{{transactionVolume}}</span>万元</p>
							<p class="p1">交易额</p>
						</div>
					</div>
				</el-col>
				<el-col :span="4" class="one-center  p-one">
					<div class="one-item flexc">
						<img src="@/assets/img/fangwenliang.png" alt="">
						<div class="one-text">
							<p><span>{{ monthlyVisits }}</span>次</p>
							<p class="p1">平台月访问量</p>
						</div>
					</div>
				</el-col>
				<el-col :span="4" class="one-center p-one">
					<div class="one-item flexc">
						<img src="@/assets/img/jiaoyishu.png" alt="">
						<div class="one-text">
							<p><span>{{ monthlyTransactions }}</span>单</p>
							<p class="p1">平台月交易数</p>
						</div>
					</div>
				</el-col>
				<el-col :span="4" class="one-center p-one">
					<div class="one-item flexc">
						<div class="one-text one-text1 scrollable-container">
							<p class="scrollable-content" v-for="(item,index) in news" :key="index"> {{item.name}}</p>

						</div>
					</div>
				</el-col>
			</el-row>
		</div>
		<div class="two">
			<h2>平台介绍</h2>
			<p>致力于构建北斗生态应用的电缆产业工业互联网平台，实现传统制造企业向具有生态主导力的平台型企业转型</p>
			<img src="@/assets/img/pingtaineirong.png" alt="" class="pimg">
			<img src="@/assets/img/phone/pingtaijieshao.png" alt="" class="mimg">
		</div>
		<div class="three">
			<div class="three-img">
				<h2>产品及服务</h2>
				<el-row type="flex" justify="center" :gutter="40">
					<el-col :span="5" :xs="24">
						<div class="three-bgc" style="text-align: left;">
							<!-- <img src="@/assets/img/chanpin-zuo.png" alt=""> -->
							<div class="tab" v-for="(item,index) in three_tab" @click="isok=index"
								:class="{'tabs':isok==index}">
								{{item}}
							</div>

						</div>
					</el-col>
					<el-col :span="11" :xs="24" v-if="isok==0">
						<div class="over">
							<el-row class="row-bg" :gutter="20">
								<el-col :span="8" :xs="24" v-for="(item,index) in hardwareServiceList">
									<div class="text">
										<h4>
											<el-link :href="item.url" target="_blank" :underline="false" type="primary">
												<span style="font-weight: bold;">{{ item.key }}</span>
											</el-link>
										</h4>
										<p>{{ item.val }}</p>
									</div>
								</el-col>
							</el-row>
						</div>
					</el-col>
					<el-col :span="11" :xs="24" v-if="isok==1">
						<div class="over">
							<el-row class="row-bg" :gutter="20">
								<el-col :span="8" :xs="24" v-for="(item,index) in applicationServiceList">
									<div class="text">
										<h4><el-link :href="item.url" target="_blank" :underline="false" type="primary">
												<span style="font-weight: bold;">{{ item.key }}</span>
											</el-link></h4>
										<p>{{ item.val }}</p>

									</div>
								</el-col>
							</el-row>
						</div>
					</el-col>
				</el-row>
			</div>
		</div>
		<div class="four">
			<h2>解决方案</h2>
			<div class="four-box">
				<el-tabs :tabPosition="tabPosition">
					<el-tab-pane label="安全用电解决方案">
						<div class="right">

							<h3>安全用电解决方案</h3>
							<p>提供基于物联网、云计算、大数据及移动互联网技术服务，构建双碳目标和能耗双控政策下服务于用户侧智能用电、智慧用电、绿色低碳应用实践的开放型综合服务生态解决方案</p>
							<router-link to="/secure">
								<div class="text">查看详情></div>
							</router-link>

							<img src="@/assets/img/yongdian.png" alt="">
						</div>

					</el-tab-pane>
					<el-tab-pane label="电能综合治理解决方案">
						<div class="right">
							<h3>电能综合治理解决方案</h3>
							<p>提供基于物联网、AI、云计算等技术，构建企业节能环保，低碳化转型的电能综合治理解决方案，主要应用于配电网、轨道交通、智能楼宇、机械制造等领域。</p>
							<router-link to="/electricity">
								<div class="text">查看详情></div>
							</router-link>
							<img src="@/assets/img/yongdian.png" alt="">
						</div>
					</el-tab-pane>

				</el-tabs>
			</div>
		</div>
		<div class="five">
			<h2>合作企业</h2>
			<div class="img">
				<img src="@/assets/img/1.png" alt="">
				<img src="@/assets/img/2.png" alt="">
				<img src="@/assets/img/3.png" alt="">
				<img src="@/assets/img/4.png" alt="">
				<img src="@/assets/img/5.png" alt="">
				<img src="@/assets/img/6.png" alt="">
				<img src="@/assets/img/7.png" alt="">
				<img src="@/assets/img/8.png" alt="">
				<img src="@/assets/img/9.png" alt="">
				<img src="@/assets/img/10.png" alt="">
				<img src="@/assets/img/11.png" alt="">
				<img src="@/assets/img/12.png" alt="">
				<img src="@/assets/img/13.png" alt="">
				<img src="@/assets/img/14.png" alt="">
				<img src="@/assets/img/15.png" alt="">
				<img src="@/assets/img/16.png" alt="">
				<img src="@/assets/img/17.png" alt="">
				<img src="@/assets/img/18.png" alt="">
			</div>
		</div>
		<div class="six">
			<h2>集团动态与新闻</h2>
			<router-link to="/new" target="_blank">
				<p class="more">查看更多<i class="el-icon-arrow-right"></i> </p>
			</router-link>

			<el-row type="flex" justify="center" :gutter="20">
				<el-col :span="6" :xs="24">
					<div class="top">
						<img src="@/assets/img/new.png" alt="" width="100%">
						<h3>省科投及成员企业一行莅临华泰联合调研</h3>
						<div class="time">2023-07-29</div>
					</div>
				</el-col>
				<el-col :span="10" :xs="24">
					<div class="content" v-for="(item,index) in news" :key="index">
						<div class="pad" @click="gonewdetails(item.objectId)">
							<el-row type="flex" justify="space-between">
								<el-col :span="16" :xs="24">
									<div class="title">
										{{item.name}}
									</div>
								</el-col>
								<el-col :span="5" :xs="24">
									<div class="time">
										{{item.createdAt}}
									</div>
								</el-col>
							</el-row>
							<div class="text">
								{{item.pageDesc}}
							</div>
						</div>
					</div>
				</el-col>

			</el-row>
		</div>
		<div class="seven">
			<h2>社会责任与信任中心</h2>
			<p class="more" @click="gozz">查看更多<i class="
          el-icon-arrow-right"></i>
			</p>
			<div class="seven-box">
				<div class="left">
					<p>华泰作为行业引领的工业互联网平台，不断夯实自身业务能力，为用户提供合规、安全、高效的服务，同时也肩负社会责任，投身公共服务事业，致力于标准引领。</p>
					<div class="bottom">
						<div class="item" @click="gozz">
							<img src="@/assets/img/zhengshu.png" alt="">
							<div>
								国际国内证书<i class="el-icon-arrow-right"></i>
							</div>
						</div>
						<div class="item" @click="gozz">
							<img src="@/assets/img/shiyan.png" alt="">
							<div>
								标准实验室<i class="el-icon-arrow-right"></i>
							</div>
						</div>
						<div class="item" @click="gozz">
							<img src="@/assets/img/guoqi.png" alt="">
							<div>
								国企担当<i class="el-icon-arrow-right"></i>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<Bottom></Bottom>
	</div>
</template>
<script>
	import Nav from '@/components/Nav.vue'
	import Swiper from '@/components/Swiper.vue'
	import Bottom from '@/components/bottom.vue'
	import {
		getInfo
	} from "@/utils/api.js";
	import Cookies from "js-cookie";
	export default {
		components: {
			Nav,
			Swiper,
			Bottom
		},
		data() {
			return {
				info: [],
				news: [],
				member: null,
				transactionVolume: null,
				monthlyVisits: null,
				monthlyTransactions: null,
				three_tab: ['全链路应用服务', '安全稳定的硬件能力'],
				hardwareServiceList: [{
						key: '电缆商城',
						val: "为国内客户提供优质的电缆购买服务",
						url: "https://www.yellowrivercloudcable.cn/"
					},
					{
						key: '云缆汇',
						val: "为用户提供金融服务",
						url: "/cloudcable"
					},
					{
						key: '黄河云缆',
						val: "为国外客户提供优质的电缆购买服务",
						url: "https://www.yellowrivercloudcable.com/"
					},
					{
						key: '自动识别报价',
						val: "为客户提供快速的自动报价服务",
						url: "https://bj.yrccable.com/#/"
					},
					{
						key: '产品溯源系统',
						val: "为客户提供可实时查看订单生产、检测、物流等信息服务",
						url: "http://218.29.109.70:11784/#/order"
					},
					{
						key: '外综服',
						val: "为外贸客户提供通关、结汇、退税等服务",
						url: "https://wzf.hnymqq.com/"
					},

					{
						key: '供应链管理平台',
						val: "为用户提供供应链管理相关服务",
						// url: "http://gyl.yrccable.com/"
            url: "http://106.54.168.40:17086"
					},
					{
						key: 'MES系统',
						val: "为用户提供订单执行过程中的管理服务",
						url: "http://218.29.109.70:11786"
					},
					{
						key: '数采平台',
						val: "为客户提供物联网设备数据采集、处理、监控等服务",
						url: "http://dcp.huatailianhe.com"
					},
					{
						key: '电缆询价系统',
						val: "为用户提供报价和技术设计服务",
						url: "http://bj.huatailianhe.com/"
					},
					{
						key: '区块链系统',
						val: "为客户区块链服务",
						url: "https://baas.yrccable.com/index"
					},
					{
						key: '项目报备',
						val: "为用户提供销售项目管理服务",
						url: "https://xs.yrccable.com/#/"
					}
					// { key: '一体化管理系统', val: "为用户销售、采购、仓储、财务、生产等服务", url: ""},
					// { key: '低代码平台', val: "为用户提供管理系统快速搭建服务", url: ""}
				],
				applicationServiceList: [{
						key: '北斗融合终端',
						val: "为用户提供边缘端端数据采集、存储、处理、下发等服务",
						url: "/bigDipper"
					},
					{
						key: '智能断路器',
						val: "为用户提供电路保护与监测服务快",
						url: "/broken"
					},
					{
						key: 'FCSZ电能综合治理终端',
						val: "为用户提供电能质量监测与治理服务",
						url: "/fcfz"
					}
				],
				isok: 0,
				tabPosition: 'left',
				screenWidth: window.innerWidth // 初始屏幕宽度
			}
		},
		created() {
			if (this.$route.query.userName) {
				Cookies.set('phone', this.$route.query.userName, {
					expires: 7
				});
				this.phone = this.$route.query.userName
			}
			if (this.$route.query.error) {
				this.$message({
					showClose: true,
					message: this.$route.query.error,
					offset: '100',
					type: 'error'
				})
			}
			this.get()
		},
		methods: {
			// 去新闻详情
			gonewdetails(e) {
				this.$router.push({
					path: '/newdetails',
					query: {
						id: e
					}
				});
			},
			async get() {
				const res = await getInfo()
				if (res.status == 200) {
					this.info = res.data.settingList
					if (res.data.sectionList) {
						this.news = res.data.sectionList.slice(0, 3)
					}
					if (this.info) {
						this.info.forEach(item => {
							if (item.paramName === 'index.member') {
								this.member = item.paramValue
							}
							if (item.paramName === 'index.monthlyTransactions') {
								this.monthlyTransactions = item.paramValue
							}
							if (item.paramName === 'index.monthlyVisits') {
								this.monthlyVisits = item.paramValue
							}
							if (item.paramName === 'index.transactionVolume') {
								this.transactionVolume = item.paramValue
							}
						});
					}
				}
			},
			gozz() {
				this.$router.push('/aptitude');
			},
			onResize() {
				// console.log(this.isMobile);
				// 更新屏幕宽度
				this.screenWidth = window.innerWidth;
				if (this.isMobile) {
					this.tabPosition = 'top'
				} else {
					this.tabPosition = 'left'
				}
			},
			pauseAnimation() {
				const scrollableContent = this.$el.querySelector('.scrollable-content');
				scrollableContent.style.animationPlayState = 'paused'; // 鼠标悬停时暂停动画
			},
			resumeAnimation() {
				const scrollableContent = this.$el.querySelector('.scrollable-content');
				scrollableContent.style.animationPlayState = 'running'; // 鼠标移开后继续动画
			}
		},
		computed: {
			// 添加一个计算属性，用于检测屏幕宽度是否小于某个阈值
			isMobile() {
				return this.screenWidth < 768; // 假设小于768px为移动设备
			}
		},

		mounted() {
			this.onResize();
			// 在组件挂载后，监听窗口大小变化
			window.addEventListener('resize', this.onResize);
		},
		beforeUnmount() {
			// 在组件销毁前，移除窗口大小变化监听器，以防止内存泄漏
			window.removeEventListener('resize', this.onResize);
		},
	}
</script>
<style lang="scss" scoped>
	.one {
		margin: 14px 0;

		.one-center {
			display: flex;
			justify-content: center;
		}

		.one-item {

			img {
				margin-right: 20px;
			}
		}

		.one-text {
			span {
				font-size: 24px;
			}

			font-weight: bold;
			font-size: 16px;
			font-weight: 500;
			color: #2065FC;

			.p1 {
				color: #131313;
				font-size: 14px;
			}
		}

		.one-text1 {
			font-weight: bold;
			width: 220px;
			height: 80px;
			padding: 10px;
			background: #F6F8FF;
			overflow: hidden;

			p {
				width: 220px;
				height: 80px;
			}
		}

		@keyframes scrollAnimation {
			0% {
				transform: translateY(0);
			}

			100% {
				transform: translateY(-160px);
				/* 向上滚动80像素 */
			}
		}

		.scrollable-container .scrollable-content {
			animation: scrollAnimation 5s linear infinite;
			/* 2秒滚动一次 */
		}

	}

	.two {
		text-align: center;
		padding-top: 50px;
		background: #F6F8FF;

		.mimg {
			display: none;
		}

		p {
			font-size: 16px;
			font-weight: bold;
			color: #666666;
			margin-top: 20px;
			margin-bottom: 50px;
		}

	}

	.three {
		text-align: center;
		background: #F6F8FF;

		.three-img {
			background-image: url(@/assets/img/chanpin-bj.png);
			background-repeat: no-repeat;
			background-size: auto;
			background-position: 50% 50%;
			height: 740px;
			padding-top: 50px;

			h2 {
				margin-bottom: 50px;
			}

			.over {
				height: 530px;
				overflow-y: auto;
				overflow-x: hidden;
				padding-right: 20px;
			}

			.three-bgc {
				height: 530px;
				background-image: url(../assets/img/chanpin-zuo.png);
				background-size: auto;
				z-index: 9999;
				padding-top: 30px;

				.tab {
					font-size: 24px;
					font-weight: bold;
					padding-left: 20%;
					margin-bottom: 30px;
					cursor: pointer;
					color: #D1DFFF;
				}

				.tabs {
					color: #FFFFFF;

				}
			}

			.text {
				background-color: #fff;
				text-align: left;
				padding: 20px;
				margin-bottom: 29px;
				height: 150px;

				p {
					font-size: 14px;
					font-weight: 500;
					color: #666666;
					line-height: 28px;
				}

				.right {
					font-size: 14px;
					font-weight: 500;
					color: #2065FC;
					text-align: right;
				}
			}
		}
	}

	.four {
		text-align: center;
		background-image: url(@/assets/img/jiejue-bj.png);
		background-repeat: no-repeat;
		background-size: auto;
		background-position: 50% 50%;
		height: 759px;
		padding-top: 50px;

		.four-box {
			width: 80%;
			height: 560px;
			background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0.4) 100%);
			border-radius: 10px 10px 10px 10px;
			opacity: 1;
			margin: auto;
			margin-top: 40px;
			padding: 60px 50px 0 50px;
			text-align: left;

			.right {
				margin-left: 50px;

				img {
					width: 80%;
				}

			}

			p {
				font-size: 16px;
				font-weight: bold;
				color: #333333;
				line-height: 32px;
				margin: 24px 0 15px 0;
			}

			.text {
				font-size: 16px;
				font-weight: 500;
				color: #2065FC;
				margin-bottom: 35px;
				cursor: pointer;
			}
		}
	}

	.five {
		h2 {
			text-align: center;
			margin: 48px 0 53px 0;
			color: #2065FC;
		}

		.img {
			width: 80%;
			margin: auto;
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;

			img {
				margin-bottom: 30px;
				cursor: pointer;
				transition: 1s all;
			}

			img:hover {
				transform: translateY(-5px);
				box-shadow: 2px 0 5px #b0b0b0;
			}
		}
	}

	.six {
		padding-top: 42px;
		background: #F6F8FF;

		h2,
		p {
			text-align: center;
		}

		p {
			margin: 17px 0 21px 0;
			font-size: 14px;
			font-weight: 500;
			color: #2065FC;
		}

		h3 {
			margin: 12px 0 10px 0;
		}

		.time {
			font-size: 14px;
			font-weight: 500;
			color: #666666;
		}

		.top {
			background-color: #fff;
			padding-bottom: 10px;

			h3,
			div {
				margin-left: 20px;
			}
		}

		.pad {
			cursor: pointer;

			.title:hover {
				color: #2065FC;
			}
		}

		.content {
			border-bottom: 1px solid #D9D9D9;
			padding: 10px 0 15px 0;

			.title {
				font-size: 20px;
				font-weight: 500;
				color: #333333;
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
			}

			.text {
				font-size: 16px;
				font-weight: 500;
				color: #666666;
				line-height: 24px;
				margin-top: 15px;
			}
		}
	}

	.seven {
		padding-top: 42px;
		background: #F6F8FF;

		h2,
		p {
			text-align: center;
		}

		.more {
			cursor: pointer;
		}

		p {
			margin: 17px 0 21px 0;
			font-size: 14px;
			font-weight: 500;
			color: #2065FC;
		}

		.seven-box {
			background-image: url(@/assets/img/xinren1.png);
			background-repeat: no-repeat;
			background-size: auto;
			background-position: 50% 50%;
			height: 464px;

			.left {
				padding-top: 50px;

				p {
					text-align: left;
					width: 532px;
					font-size: 20px;
					font-weight: bold;
					color: #333333;
					line-height: 32px;
					margin: 0px 0 64px 20%;
				}

				.bottom {
					margin-left: 20%;
					width: 500px;
					display: flex;
					justify-content: space-between;

					.item {
						display: flex;
						flex-direction: column;
						align-items: center;
						cursor: pointer;

						div {
							font-size: 16px;
							font-weight: bold;
							color: #333333;
							line-height: 32px;
						}

						i {
							display: inline-block;
							margin-left: 5px;
							font-weight: bold;
							color: #2065FC;
						}
					}
				}
			}
		}
	}

	@media screen and (max-width: 768px) {


		.one {
			.p-one {
				display: none;
			}
		}

		.two {
			.pimg {
				display: none;
			}

			p {
				padding: 0 15px;
			}

			.mimg {
				display: block;
			}
		}

		.three {
			height: 800px;


			.el-row--flex {
				flex-direction: column
			}

			.over {
				height: 500px !important;
				overflow-y: auto;
				overflow-x: hidden;
				padding-right: 0px !important;
			}

			.three-bgc {
				height: auto !important;
				background-image: none;
				display: flex;
				justify-content: space-evenly;
			}

			.tab {
				font-size: 18px !important;
				padding-left: 0% !important;
				margin-bottom: 30px;
				cursor: pointer;
				color: #D1DFFF;
			}

			.text {
				margin: 0 20px 30px;
				margin-bottom: 30px;
			}
		}

		.four {
			height: 640px;
		}

		.four-box {
			width: 100% !important;
			padding: 10px !important;
			text-align: center !important;

			.right {
				margin-left: 0px !important;
			}

			img {
				width: 100%;
			}
		}

		.five {
			.img {
				width: 90%;

				img {
					width: 45%;
				}
			}
		}

		.six {
			.el-row--flex {
				flex-direction: column
			}

			.time {
				margin: 10px 0 0 10px;
			}

			.pad {
				padding: 0 10px;
			}

		}

		.seven {

			.seven-box {
				background-image: none;
				background-color: #ebf2ff;
				height: 400px;

			}

			.left {
				p {
					width: 80% !important;
					margin: auto !important;
					font-size: 17px !important;
				}
			}

			.bottom {
				width: 100% !important;
				display: flex;
				justify-content: space-evenly !important;
				margin-top: 30px;
				margin-left: 0 !important;
			}
		}
	}
</style>